import React from 'react'

import realign from "../../../../static/img/realign/realign.png"

const HeroSection = () => (
  <section id="realign-hero-section-realign">
    <div className="container">
      <div className="row kk">
       

        <div className="col-lg-6 col-md-6 realign-hero-text rea mt-5 mb-auto">
          <img src={realign} alt="realign" className='img-fluid'/>
          <h1>Fix Your Posture</h1>
          <p>
          Using the latest AI, analyse & fix your posture with Re/Align
          </p>

          <ul className="desktop-app-realign">
            <li>
              <a className="btn d-flex app-link rea" href="https://apps.apple.com/us/app/re-align/id1538124409">
                <div className="mt-auto mb-auto mr-2 pr-2"><i className="fa fa-apple" aria-hidden="true"></i></div>
                <div>Download on the<br/>App Store</div>
              </a>
            </li>
          </ul>
        </div>
          </div>
    </div>
  </section>
)

export default HeroSection;