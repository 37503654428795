import React from 'react';
import "./index.css"
import {Link} from "gatsby";

const Footer4 = () => (
    <>
      <footer id="realign-footer-realign">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center realign-footer-widget rea">
              <a className="navbar-brand mb-5" href="#">
                <img className="img-fluid" src={require("../../../static/img/splittable/logo.png")} alt="Logo"/>
              </a>
            </div>
          </div>

          <div className="row realign-copyright-wrap">
            <div className="col-md-6 realign-footer-copyright rea">
              <p>© 2021 <a href="#">Ever10</a>. All Rights Reserved.</p>

            </div>
            <div className="col-md-6 realign-footer-terms-wrap">
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><Link to="/eula">End User License Agreement</Link></li>
              </ul>
            </div>
          </div>

        </div>
      </footer>
      {/* Mobile Footer */}
      <div id="realign-footer-realign-mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center realign-footer-widget rea">
              <a className="navbar-brand mb-5" href="#">
                <img className="img-fluid" src={require("../../../static/img/splittable/logo.png")} alt="Logo"/>
              </a>
            </div>
          </div>

          <div className="row realign-copyright-wrap">
            <div className="col-md-6 realign-footer-copyright rea">
              <p>© 2021 <a href="#">Ever10</a>. All Rights Reserved.</p>

            </div>
            <div className="col-md-6 realign-footer-terms-wrap rea">
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><Link to="/eula">End User License Agreement</Link></li>
              </ul>
              <div style={{marginBottom: "50px"}}></div>
            </div>
          </div>
        </div>
      </div>

    </>
)

export default Footer4;