import React from 'react'
import tick from "../../../../static/img/realign/tick.png"

const WhyItMatters = () => (
    <section id="realign-why-it-matters rea">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1 realign-why-it-matters-content rea">
            <header>
              <h3 className="why-rea">Alignment, </h3>
              <div className="why-rea">Why it matters?</div>
              <hr/>
            </header>
            <div className="mobile-bar-rea">
              <img className="mobile-bar" src={require('../../../../static/img/realign/mobile-bar.png')} alt=""/>
            </div>
            <p>
              Modern lifestyle tends to encourage sitting hunched over laptops and staring down at our mobile phones.
              The head weighs about 12 lbs (5.4 kg) on average, but poor posture can add as much as 48 lbs (21 kg) extra weight on the spine, as commonly happens when staring down at our phones.
              This can cause neck, shoulder and back pain as some muscles become weak, and others tight, potentially compounding bad habits to form poor posture.
              </p>
            <p>
              Fortunately stretches, exercises and movement can help return our spine to it's natural alignment by resolving these muscle balances.
            </p>

            <ul className="rea">
              <li className="rea"><img src={tick} className="rea" alt="tick"/><p>Reduced pain</p></li>
              <li className="rea"><img src={tick} className="rea" alt="tick"/><p>Look & feel more confident</p></li>
              <li className="rea"><img src={tick} className="rea" alt="tick"/><p>Increased energy</p></li>
            </ul>
          </div>
          <div className="col-lg-5 col-lg-6">
            <div className="allignment-rea">

              <img className="img-fluid" src={require("../../../../static/img/realign/alignment.png")} alt="Allignment Matters"/>
            </div>
          </div>
        </div>
      </div>
    </section>
)

export default WhyItMatters;