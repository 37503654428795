import React from 'react'

const GetYourAppSection = () => (

    <>
      <div className="container">
        <div className="row get-rea">
          <div className="col-lg-6 shape">
            <div className="appstore-rea mbl-dwn-app">
              <span className="appstore-button">
                <div className="dwn-rea">Download on the</div>
                <div className="apst-rea"><a href="https://apps.apple.com/us/app/re-align/id1538124409">APP STORE</a></div>
              </span>
            </div>
          </div>
          <div className="col-lg-1"/>
          <div className="col-lg-5">
            <div className="mbl-dwn-app">
              <div className="dwn-rea">Download on the</div>
              <div className="apst-rea"><a href="https://apps.apple.com/us/app/re-align/id1538124409">APP STORE</a></div>
            </div>
            <div className="mobile-rea">
              <img src={require("../../../../static/img/realign/mobile.png")} alt="Mobile Img"/>
            </div>
          </div>
        </div>
      </div>
    </>
)

export default GetYourAppSection;